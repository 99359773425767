<template>
  <div
    class="flex items-center justify-end flex-1 h-24 px-4 py-6 space-x-4"
  >
    <MenuIcon
      v-if="!active"
      @click="toggleActive"
      class="mr-4 xl:mr-8"
    >
    </MenuIcon>
    <CloseIcon
      v-else
      @click="toggleActive"
      class="mr-4 xl:mr-8"
    >
    </CloseIcon>
  </div>
  <div
    v-if="active"
    class="absolute inset-x-0 w-screen h-screen pt-12 overflow-y-scroll"
    :class="colors()"
  >
    <!-- Main items -->
    <div
      v-for="(item, index) in items"
      :key="index"
      class="mt-5"
    >
      <a
        :href="item.url"
        class="block p-3 mx-5 mb-3 text-lg font-bold hover:text-xl"
      >
        {{ item.name }}
      </a>
      <!-- Sub items (if existent) -->
      <div
        v-if="item.sub_items.length"
      >
        <a
          v-for="(sub_item, index) in item.sub_items"
          :key="index"
          :href="sub_item.url"
          class="block px-5 py-2 pb-3 mx-10 mb-3 text-base hover:font-bold"
          :class="hoverColors()"
        >
          {{ sub_item.name }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import MenuIcon from "../../../static/svg/menu.svg";
import CloseIcon from "../../../static/svg/close.svg";
import global from "../../global";

export default {
  components: {MenuIcon, CloseIcon},
  props: {
    items: Array,
  },
  setup() {
    let active = ref(false);
    const instance = getCurrentInstance();
    const { colors, hoverColors } = global;

    function toggleActive() {
      // Prevent body from scrolling in iOS when menu modal is active
      if (active.value) {
        document.body.classList.remove("overflow-hidden");
        window.addEventListener('scroll', instance.parent.onScroll);
        active.value = false;
      } else {
        document.body.classList.add("overflow-hidden");
        window.removeEventListener('scroll', instance.parent.onScroll);
        active.value = true;
      }
    }

    return { active, toggleActive, colors, hoverColors };
  }
};
</script>
