<template>
  <teleport :to="target">
    <transition name="fade">
      <div
        v-show="showMenu"
        class="absolute inset-x-0 top-0 z-50 flex w-screen opacity-1"
        :class="colors()"
      >
        <div class="pl-4 mt-4 lg:mt-8 xl:pl-8">
          <a href="/">
            <HeaderLogo class="scale-125" :class="hoverColors()" />
          </a>
        </div>
        <div class="flex-1">
          <MenuSmall
            v-if="['xs', 'sm', 'md'].indexOf(`${$mq}`) > -1"
            :items="items"
          >
            <div>Hi!</div>
          </MenuSmall>
          <MenuLarge
            v-if="['lg', 'xl', 'xxl'].indexOf(`${$mq}`) > -1"
            :items="items"
            class="lg:pr-4 xl:pr-8"
          >
          </MenuLarge>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, onMounted } from "vue";
import debounce from "lodash/debounce";
import MenuLarge from "./partials/MenuLarge";
import MenuSmall from "./partials/MenuSmall";
import HeaderLogo from "../../static/svg/header-logo.svg";
import global from "../global";

export default {
  components: { HeaderLogo, MenuLarge, MenuSmall },
  props: {
    target: {
      type: String,
      default: "#mytarget"
    }
  },
  setup(props) {
    let targetElement = ref(null);
    let items = ref([]);

    let lastScrollTop = ref(0);
    let showMenu = ref(true);
    const { state, colors, hoverColors } = global;

    function onScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop <= lastScrollTop.value) {
        showMenu.value = true;
      } else {
        showMenu.value = false;
      }
      lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop;
    }

    function setColors() {
      let color = "text-lviolet-700";
      let bgColor = "bg-lblue-300";
      if (targetElement.value && targetElement.value.hasAttribute("color")) {
        color = targetElement.value.getAttribute("color").trim();
      }
      if (targetElement.value && targetElement.value.hasAttribute("bg-color")) {
        bgColor = targetElement.value.getAttribute("bg-color").trim();
      }
      let colorValue = color.split("-")[2];
      let bgColorValue = bgColor.split("-")[2];

      let hoverColorValue =
        parseInt(colorValue) > 400
          ? Math.max(parseInt(colorValue) - 100, 100)
          : Math.min(parseInt(colorValue) + 100, 700);
      let bgHoverColorValue =
        parseInt(bgColorValue) > 400
          ? Math.max(parseInt(bgColorValue) - 100, 100)
          : Math.min(parseInt(bgColorValue) + 100, 700);
      state.color = color;
      state.bgColor = bgColor;
      state.hoverColor = color.replace(colorValue, `${hoverColorValue}`);
      state.bgHoverColor = bgColor.replace(
        bgColorValue,
        `${bgHoverColorValue}`
      );
    }

    onMounted(() => {
      window.addEventListener("scroll", debounce(onScroll, 10));
      targetElement.value = document.getElementById(props.target.substring(1));
      items.value = targetElement.value
        ? JSON.parse(targetElement.value.getAttribute("x-data"))
        : [];
      setColors();
    });

    return { targetElement, items, showMenu, state, colors, hoverColors };
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
