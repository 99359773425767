import { createApp } from "vue";
import App from "./App.vue";
import VueMq from "vue3-mq";
import "../static/scss/main.scss";

const app = createApp(App);

app
  .use(VueMq, {
    breakpoints: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      xxl: Infinity
    },
    defaultBreakpoint: "sm"
  })
  .mount("#app");
