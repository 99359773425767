<template>
  <HeaderMenu target="#header-menu" />
</template>

<script>
import HeaderMenu from "./components/Menu";
// import HeaderMenu from "./components/HeaderMenu";

export default {
  name: 'App',
  components: {
    HeaderMenu
  }
}
</script>
