<template>
  <div
    class="flex items-center justify-end flex-1 h-32 px-4 py-6 space-x-4"
  >
    <MenuLargeItem
      v-for="(item, index) in items"
      :key="index"
      :item=item
      class="relative p-1 pb-2"
    >
    </MenuLargeItem>
  </div>
</template>

<script>
import MenuLargeItem from "./MenuLargeItem";

export default {
  components: {MenuLargeItem},
  props: {
    items: Array,
  },
  setup() {
    return {};
  }
};
</script>
