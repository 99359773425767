<template>
  <transition name="fade">
  <div
    class="absolute z-0 px-4 py-4 -left-4 top-14"
    :class="colors()"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="hoverColors()"
    >
    <a
      :href="item.url"
      class="inline-block min-w-60 px-2 py-6"
    >
      {{ item["name"] }}
    </a>
    </div>
  </div>
  </transition>
</template>

<script>
import { getCurrentInstance, computed } from "vue";
import global from '../../global';

export default {
  props: {
    items: Array,
  },
  setup() {
    const { colors, hoverColors } = global;
    const instance = getCurrentInstance();
    const parentActive = computed(() => {
      return instance.parent.active
    });
    return { parentActive, colors, hoverColors };
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition:
    opacity 0.3s ease-out,
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>
