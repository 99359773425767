<template>
  <div
    @mouseenter="active=true"
    @mouseleave="active=false"
    class="relative"
  >
    <div class="z-10 flex px-3 py-2 text-lg xl:text-xl">
      <a :href="item.url">
        {{ item["name"] }}
      </a>
      <ChevronDown
        v-if="item.sub_items.length"
        class="pt-2 pl-1"
      >
      </ChevronDown>
    </div>
    <MenuLargeDropdown
      v-if="item.sub_items.length"
      v-show="active"
      :items="item.sub_items"
    >
    </MenuLargeDropdown>
  </div>
</template>

<script>
import { ref } from 'vue';
import MenuLargeDropdown from "./MenuLargeDropdown";
import ChevronDown from "../../../static/svg/chevron-down.svg";

export default {
  components: {MenuLargeDropdown, ChevronDown},
  props: {
    item: Object,
  },
  setup() {
    let active = ref(false);
    return { active };
  }
};
</script>
