import { reactive } from 'vue';

const state = reactive({
  color: "",
  bgColor: "",
  hoverColor: "",
  bgHoverColor: "",
});

// Getter
const colors = () => `${state.color} ${state.bgColor}`;
const hoverColors = () => `${state.color} hover:${state.hoverColor} ${state.bgColor} hover:${state.bgHoverColor}`;

// Setter
const setColors = (color, bgColor) => {
  console.log(color, bgColor)
  // Get numeric color values (e.g. 300 if color is bg-blue-300)
  let colorValue = color.split('-')[2]
  let bgColorValue = bgColor.split('-')[2]

  let hoverColorValue = parseInt(colorValue) > 400 ? Math.max(parseInt(colorValue) - 100, 100) : Math.min(parseInt(colorValue) + 100, 700)
  let bgHoverColorValue = parseInt(bgColorValue) > 400 ? Math.max(parseInt(bgColorValue) - 100, 100) : Math.min(parseInt(bgColorValue) + 100, 700)
  state.color = color;
  state.bgColor = bgColor;
  state.hoverColor = color.replace(colorValue, `${hoverColorValue}`);
  state.bgHoverColor = bgColor.replace(bgColorValue, `${bgHoverColorValue}`);

};

export default { state, colors, hoverColors, setColors };

